<template>
	<div>
	<ContentHeader title="Pemesanan Obat" url="/pemesanan-obat" subTitle="Faktur Pemesanan" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="card">
        <div class="card-body pl-4" style="display: block;">
           <h4 class="text-center mb-3">SURAT PESANAN</h4>
           <div class="row">
             <div class="col">
              <p>{{ pembeli.nama }}</p> 
              <p>{{ pembeli.alamat }}</p>
              <p>Telp. {{ pembeli.telepon }}</p>
             </div>
             <div class="col"></div>
             <div class="col">
               <p>No SP : {{ data.nomor_faktur }}</p>
               <p>Tanggal : {{ data.created_at }}</p>
               <p>Kepada : {{ penjual.nama }}</p>
             </div>
           </div>

           <table class="table table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Kode Barang</th>
                  <th scope="col">Nama Barang</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Satuan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data.pembelian_detail" :key="index">
                  <td class="text-sm" scope="row">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.produk.kode }}</td>
                  <td class="text-sm">{{ row.produk.nama }}</td>
                  <td class="text-sm">{{ row.jumlah }}</td>
                  <td class="text-sm">{{ row.produk.satuan }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mt-5">
              <div class="border-bottom border-dark mr-5 " style="height: 100px;width: 100px;">
                <p class="text-center">Hormat Kami</p>
              </div>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { ref, onMounted, reactive, computed } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const cabang = computed(() => store.getters['auth/cabang'])
const route = useRoute()
const pemesanan_id = route.params.id
const data = ref([])
const pembeli = reactive({
  'nama' : '',
  'alamat' : '',
  'telepon' : ''
})
const penjual = reactive({
  'nama' : ''
}) 
const getData = async () => {
  let response = await axios.get(`api/pembelian/getPemesananDetail/${cabang.value.tipe}/${pemesanan_id}`)
  
  if (response != 'kosong') {
    data.value = response.data
    pembeli.nama = response.data.pembeli.nama
    pembeli.alamat = response.data.pembeli.alamat
    pembeli.telepon = response.data.pembeli.telepon
    penjual.nama = response.data.penjual.nama
  } 
}

onMounted(() => {
  getData()
})
</script>